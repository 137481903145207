import React, { useState } from "react";
import Layout from "../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./style.scss";

import ReactPlayer from "react-player";

import img1 from "../../../images/01.jpg";
import img2 from "../../../images/05.jpg";
import img3 from "../../../images/03.jpg";
import img4 from "../../../images/04.jpg";
import img5 from "../../../images/02.jpg";
import img6 from "../../../images/06.jpg";
import img7 from "../../../images/07.jpg";

import readyfornewnormal from "../../../images/ReadyfortheNewNormal2.jpg";
import makarsankranti from "../../../images/makar-sankranti.png";
import primaryfelicitation1 from "../../../images/primaryfelicitation1.png";
import seniorfelicitation from "../../../images/seniorfelicitation.png";
import geetachanting2020falicitation from "../../../images/24thInterSchoolGeetaChantingCompetition2020FelicitationCeremony.jpg";
import VideoPopup from "../../VideoPopup";
import SEO from "../../seo";

const imgUrl1 = "https://www.youtube.com/embed/Z2mKcqX5P0o";
const imgUrl2 = "https://www.youtube.com/embed/YpI4ir6gLPo";
const imgUrl3 = "https://www.youtube.com/embed/aKEtj2X5jjg";
const imgUrl4 = "https://www.youtube.com/embed/4L_Dqf77z7g";
const imgUrl5 = "https://www.youtube.com/embed/na1xd-pWD8M";
const imgUrl6 = "https://www.youtube.com/embed/dFT22C9kjak";
const imgUrl7 = "https://www.youtube.com/embed/YGRrVFiBhc0";

const data = [
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/1.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/2.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/3.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/4.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/5.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/6.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/7.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/8.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/9.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/10.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/11.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/12.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/13.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/14.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/15.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/16.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/17.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/18.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/19.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/20.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/21.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/22.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/23.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/24.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/25.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/26.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/27.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/28.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/29.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/30.jpg" },
  { img: "https://chinmayavvdelhi.ac.in/assets/gallery/31.jpg" },
];

const videoData = [
  {
    titleHead: "Hari Om",
    titleSubHead:
      "Awareness that blooms even in a child as young as a Grade I student",
    titleText: `<div>Ayaana Pandey from grade I pours her understanding of SDGs through a video for the 1 SDG Fest
      2021-Bringing SDGs to life. The little wise child surely wins our hearts.</div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/6iYCvcko7o8",
    date: "08 November 2021",
    imgUrlH:
      "https://chinmayavvdelhi.ac.in/assets/home/awareness-that-blooms.png",
    // link: "",
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "A New Way to Look at Arithmetic",
    titleText: `<div>Arthmetician Namit Singh presented a new way to look at Arithmatic by adding up the strengths and
      subtracting the negativities to stop Corona by making a wall and to make our country stand tall.</div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/hrHERDzvnaI",
    date: "18 October 2021",
    imgUrlH:
      "https://chinmayavvdelhi.ac.in/assets/home/a-new-way-to-look-at-arithmetic.png",
    // link: "",
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Holistic Development",
    titleText: `<div>The Vidyalaya offers ample opportunities for holistic development and Avni Pandey&#39;s performance
      proves the same.</div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/OgNIomWwhoM",
    date: "01 September 2021",
    imgUrlH:
      "https://chinmayavvdelhi.ac.in/assets/home/Holistic-Development.png",
    // link: "",
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "I am the Champion",
    titleText: `<div>With an objective to enable the students to learn about various concepts from different subjects like
      Mathematics, English, Social Science and Value Education in a fun filled way, the little champion Jatin
      Chaudhary created his own game - I am the Champion.</div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/BkbAtvbkMC8",
    date: "16 August 2021",
    imgUrlH: "https://chinmayavvdelhi.ac.in/assets/home/i-am-the-champion.png",
    // link: "",
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Health Experts",
    titleText: `<div>Surabhi Sameesh from Nur-A shares the secret of her Organic Mantra and finds her way not only to
      our hearts but also to our kitchens.
      </div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/vVHDrK3h18w",
    date: "06 August 2021",
    imgUrlH: "https://chinmayavvdelhi.ac.in/assets/home/health-experts.png",
    // link: "",
  },

  {
    titleHead: "Hari Om",
    titleSubHead: "Ready for the New Normal",
    titleText: `<div>As we enter 2021, adapting into the 'New Normal', we welcome our dear students and usher them 			in after a long gap.
      All preparations have been done, precautions have been taken and standards have been met as we start the partial resumption of classes XII and X.
      
      We wish our students loads of success and a happy journey into the world of learning.
      </div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/de28vOZWSUM",
    date: "27 January 2021",
    imgUrlH: readyfornewnormal,
    link: "/calendar/ReadyfortheNewNormal",
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Special Assembly on Makar Sankranti",
    titleText: `<div>Chinmaya Vidyalaya, New Delhi organised a special virtual assembly on the auspicious occasion 			of Makar Sankranti on Thursday 14th January 2021, to welcome the New Year with chanting of the 					auspicious 'Gayatri Mantra' for 108 times and prayed for the well being of everyone.
      </div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/puAEyTQX9Tc",
    date: "14 January 2021",
    imgUrlH: makarsankranti,
    link: "/calendar/SpecialAssemblyonMakarSankranti",
  },

  {
    titleHead: "Hari Om",
    titleSubHead: "24th Inter School Geeta Chanting Competition 2020",
    titleText: `<div>The 24th Inter School Geeta Chanting Competition was held online by Chinmaya Vidyalaya, New Delhi from 4th -6th December 2020.The three-day event witnessed an overwhelming participation of 433 students from 54 schools.</div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/r8FgELMxjgY",
    date: "4-6 December 2020",
    link: "/calendar/24thInterSchoolGeetaChantingCompetition2020",
    imgUrlH: geetachanting2020falicitation,
  },

  {
    titleHead: "Hari Om",
    titleSubHead: "Senior Felicitation Ceremony 2020",
    titleText: `<div>‘Difficult roads often lead to beautiful destinations.’ <br/>
  The senior section of Chinmaya Vidyalaya organized an Online Felicitation Ceremony - 2020 'Prashast Path’ to appreciate and applaud the efforts of the meritorious students.
      </div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/FSLHWnI_qjk",
    date: "29 November 2020",
    link: "/calendar/SeniorFelicitationCeremony2020",
    imgUrlH: seniorfelicitation,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Primary Felicitation Ceremony 2020",
    titleText: `<div>
  The Primary Department of Chinmaya Vidyalaya, New Delhi, organized the Online Annual Felicitation Ceremony-2020 ' Haunsalon Ki Udaan'. Mr. Kapil Singh Director, State Nutrition Mission, Lucknow U.P, an Alumnus of Chinmaya Vidyalaya, New Delhi, graced the occasion as the Chief Guest. 
      </div>`,
    titleSub: " ",
    tvideo: "https://www.youtube.com/embed/MFf8EpNSjg4",
    link: "/calendar/PrimaryFelicitationCeremony2020",
    date: "29 November 2020",
    imgUrlH: primaryfelicitation1,
  },

  {
    titleHead: "Hari Om",
    titleSubHead: "Spontaneous Overflow of Reverence",
    titleText: `<div>Chinamaya Vidyalaya, New Delhi offered heartfelt respect to the 'Father of the Nation' on the occasion of Gandhi Jayanti. Bhajans have been a medium of expressing faith since time immemorial.
      <br/>
      A video presentation on the bhajan - 'Vaishnav Jan To', amalgamating orchestra, music and dance, showcased the profound emotions of the students.
      </div>`,
    titleSub: " ",
    tvideo: imgUrl7,
    date: "2 October 2020",
    imgUrlH: img7,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Hindi Diwas",
    link: "/calendar/HindiDiwas",
    titleText:
      "मन की भाषा. प्रेम की भाषा| हिंदी है जन- जन की भाषा|| चिन्मय विद्‌यालय, नई दिल्ली के कक्षा प्रथम व द्वितीय के छात्र व छात्राओं ने प्रस्तुत गतिविधियों के माध्यम से हिंदी भाषा के  महत्व को उजागर किया तथा हिंदी भाषा के  प्रति अपना प्रेम  व आभार प्रकट करने का छोटा सा प्रयास किया| हिंदी दिवस की हार्दिक शुभकामनायें!",
    titleSub: " ",
    tvideo: imgUrl6,
    date: "14 September 2020",
    imgUrlH: img6,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Arise, Awake and Stop not till the goal is reached.",
    titleText:
      "On the occasion of 74th Independence Day, Chinmaya Vidyalaya, New Delhi, honours every patriot without whom freedom would not have been possible. The staff and students salute their priceless contribution in building independent India and making us proud citizens of this incredible nation.",
    titleSub:
      " Presenting inspiring glimpses of the journey of Independent India through the mesmerising photos and videos on the theme- ‘SWARAJ SE ATMA NIRBHARTA KI AUR",
    tvideo: imgUrl4,
    date: "15 August 2020",
    imgUrlH: img4,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Saluting the Tricolour",
    titleText:
      "Celebrating the glory of free India and upholding the pride and honour of being an Indian, the Principal of the Chinmaya Vidyalaya, New Delhi, Ms. Archana Soni, unfurled the tricolour for flag hoisting ceremony which concluded with offering salutes to the National flag.",
    titleSub: " ",
    tvideo: imgUrl5,
    date: "15 August 2020",
    imgUrlH: img5,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Pouring Devotion...  Faith...",
    titleText:
      "Chinmaya Vidyalaya, New Delhi put together its efforts to pay homage to Pujya Gurudev Swami Chinmayanandaji on Mahasamadhi Day and brought out a video expressing their undying devotion.",
    titleSub: " ",
    tvideo: imgUrl3,
    date: "03 August 2020",
    imgUrlH: img3,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Guru Purnima",
    titleText:
      "Students of Chinmaya Vidyalaya New Delhi bow down in reverence to the entire Guru Parampara and Pujya Gurudev Swami Chinmayanandji on the auspicious occasion of Guru Purnima.",
    titleSub: "",
    tvideo: imgUrl2,
    date: "05 July 2020",
    imgUrlH: img1,
  },
  {
    titleHead: "Hari Om",
    titleSubHead: "Founder's Day",
    titleText:
      "Chinmaya Vidyalaya celebrates its 104th Jayanti of our beloved Pujya Gurudev Swami Chinmayanandaji, the Founder's Day of the institutions.",
    titleSub:
      "On this aupsicious occasion, we offer our humble prostrations at his Lotus feet, by chanting Guru Stotram, a conversation between Lord Shiva and Parvati on the glory of the Guru, to express our heartfelt gratitude and steadfast devotion. ",
    tvideo: imgUrl1,
    date: "08 May 2020",
    imgUrlH: img2,
  },
];

export default function SchoolGallery() {
  const [activeVideo, setActiveVideo] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function onClickPopupClose() {
    setActiveVideo({});
    setShowPopup(false);
  }

  function VideoSlider({ setActiveVideo }) {
    return (
      <div className="gallery__slider">
        <Slider {...settings}>
          {videoData.map((item, i) => (
            <div className="gallery__videos__slide">
              <div className="gallery__videos__card">
                <img src={item.imgUrlH}></img>
                <button
                  onClick={() => {
                    setActiveVideo(item);
                    setShowPopup(true);
                  }}
                >
                  <i class="fa fa-play"></i>
                </button>
                {/* <ReactPlayer url={item.tvideo} /> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      />
      <VideoPopup
        onClose={onClickPopupClose}
        url={activeVideo.tvideo}
        show={showPopup}
      />
      <div className="gallery">
        <h1 className="heading">School Gallery</h1>
        <section className="gallery__videosection">
          <VideoSlider setActiveVideo={setActiveVideo} />
        </section>

        <section className="gallery__photosection">
          <div className="gallery__photos">
            {data.map((item) => (
              <div className="gallery__photos__card">
                <img src={item.img}></img>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
}
